

import '../sass/style.scss';


import {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip
} from 'bootstrap';




const bootstrap = window.bootstrap = require('bootstrap');


// var toastEl = document.getElementById('liveToast')
// var toast = new bootstrap.Toast(toastEl)
//
//
// var myToastEl = document.getElementById('liveToastBtn')
// myToastEl.addEventListener('click', function () {
//     toast.show()
// });

// Bind to the window
// window.customfunctions = {
//     foo() {
//         console.log("fot");
//     }
// };


window.customfunctions = {
    fullHeight() {
        var ele = document.getElementById('fullHeight');
        var potop = ele.offsetTop;
        ele.style.minHeight = "calc(100vh - "+(potop+20)+"px)";
    },
    triggerDropdown() {
        var dropdownElementList = [].slice.call(document.querySelectorAll('.action .dropdown-toggle'))
        var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
            dropdownToggleEl.addEventListener("mouseenter", function () {
                var dropdown = new bootstrap.Dropdown(dropdownToggleEl);
                dropdown.show();
            });
            dropdownToggleEl.parentElement.addEventListener("mouseleave", function () {
                var dropdown = new bootstrap.Dropdown(dropdownToggleEl);
                dropdown.hide();
            });
        })
    },



};

window.customfunctions.fullHeight();
window.customfunctions.triggerDropdown();



